<template>
  <div class="">
    <div class="p-0 m-0">
      <b-container>
        <b-card
          no-body
          class="mr-1"
        >
          <div class="pl-1 pt-1 ">
            <span
              class="saj-title"
              style=""
            >
              {{ $t("Search Filter") }}
            </span>
          </div>
          <div class="">
            <b-row class="px-1 pb-1">
              <b-col
                cols="12"
                md="4"
                class="mb-md-0 mb-2"
              >
                <label class="saj-text">{{ $t("Jawatan") }}:</label>
                <b-form-select
                  v-model="role"
                  size="lg"
                  class="saj-text"
                />
              </b-col>
              <b-col
                cols="12"
                md="4"
                class="mb-md-0 mb-2"
              >
                <label class="saj-text">{{ $t("Carian Nama") }}:</label>
                <b-form-input
                  id="icons-search"
                  :placeholder="$t('Search Name')"
                  size="lg"
                />
              </b-col>
            </b-row>
          </div>
        </b-card>
        <b-row>
          <span
            v-for="(item, index) in items"
            :key="index"
            class=""
          >
            <b-card
              class="mb-3 mr-2 ml-1"
              style="
        background: #ffffff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
          0px 0px 41px 9px rgba(0, 0, 0, 0.11);
        border-radius: 12px;
        height: 350px;
        width: 410px;
        overflow-y: auto;
        font-size: 22px;
      "
            >
              <b-card-body>
                <b-row>
                  <b-col cols="4">
                    <b-img
                      src="@/assets/images/user/user.svg"
                      class="img-responsive fit-image"
                      width="80px"
                      height="100px"
                    />
                  </b-col>
                  <b-col cols="8">
                    <div>
                      {{ item.user.name }}
                    </div>
                    <div>
                      {{ $t(item.position) }}
                    </div>
                    <div>
                      {{ item.score }}
                    </div>
                  </b-col>
                </b-row>
                <b-row
                  class=" m-0 mt-2"
                >
                  <b-col
                    cols="2"
                  >
                    <div>
                      <feather-icon
                        icon="MailIcon"
                        size="22"
                        stroke="black"
                      />
                    </div>
                  </b-col>
                  <b-col
                    cols="10"
                  >
                    <div>
                      {{ $t(item.user.email) }}
                    </div>
                    <div>
                      {{ item.employee_number }}
                    </div>
                    <div>
                      {{ item.mobile_number }}
                    </div>
                  </b-col>
                </b-row>
                <div>
                  <router-link
                    class="m-0"
                    :to="{name: 'UpdateProfile', params:{id: item.user.id,}}"
                  >
                    <b-button
                      class="m-0 mt-1"
                      variant="primary"
                      block
                      style="
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: 22px;"
                    >
                      {{ $t("Lihat Butiran") }}
                    </b-button>
                  </router-link>
                </div>
              </b-card-body>
            </b-card>
          </span>
        </b-row>
      </b-container>
    </div>

  </div>
</template>
<script>
import {
// BAvatar,
  BCard,
  // BCardHeader,
  BCardBody,
  BContainer,
  BImg,
  // BModal,
  BCol,
  BRow,
  BFormInput,
  //   BFormDatepicker,
  BFormSelect,
  BButton,

} from "bootstrap-vue"
import axios from "axios"

export default {
  components: {
    // BAvatar,
    BCard,
    // BCardHeader,
    BCardBody,
    BContainer,
    BImg,
    // BModal,
    BCol,
    BRow,
    BFormInput,
    // BFormDatepicker,
    BFormSelect,
    BButton,
    // BTable,

  },

  data(){
    return {
      role: "",
      filter: "",
      items: [],
    }
  },
  //   computed: {
  //       filteredItems(){
  //           return this.items.filter((item)=>{
  //               return this.item.user.name.toLowerCase().match(this.search.toLowerCase())
  //                 }
  //             ),
  //     },
  mounted(){
    this.getDetails()
  },
  methods: {
    getDetails() {
      // const params = new FormData()
      // params.append("user_id", this.id)

      axios.get(`${this.$baseUrl}/admin/getAllEmployeeStaff`).then(response => {
        // if (response.data.success === true){
        this.items = response.data.data
        //   // const profileData = response.data.data
        //   // this.item.name = profileData.name
        //   // this.item.email = profileData.email
        //   // this.item.position = profileData.employee.position
        //   // this.item.mobile_number = profileData.employee.mobile_number
        //   // this.item.employee_number = profileData.employee.employee_number
        //   // this.item.department_id = profileData.employee.department.name
        //   // this.supervisor_id = profileData.employee.supervisor.name
        //   // this.hire_date = profileData.employee.hire_date.split("-").reverse().join("-")
        // // } else {
        //   // this.$errorConfirmation()
        //   // this.errorConfirmation('danger')
        // }
      })
    },
  },
}
</script>
<style>
